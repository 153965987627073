export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const convertImageUrlToBase64 = (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject("Canvas context not found");
        return;
      }

      ctx.drawImage(img, 0, 0);
      const base64 = canvas
        .toDataURL("image/png")
        .replace(/^data:image\/png;base64,/, "");
      resolve(base64);
    };

    img.onerror = (err) => reject(err);
  });
};

export const FormatCanvasResult = (
  canvasRef: React.RefObject<HTMLCanvasElement>,
  width = 1024,
  height = 1024,
) => {
  if (!canvasRef.current) {
    console.error("Canvas not found");
    return;
  }

  const canvas = canvasRef.current;
  const resizedCanvas = document.createElement("canvas");
  const resizedContext = resizedCanvas.getContext("2d");

  resizedCanvas.height = width;
  resizedCanvas.width = height;

  resizedContext.drawImage(canvas, 0, 0, width, height);

  try {
    const jpegImage = resizedCanvas.toDataURL("image/jpeg", 0.9);

    if (!jpegImage.startsWith("data:image/jpeg")) {
      throw new Error("Failed to generate JPEG format");
    }

    return jpegImage;
  } catch (error) {
    console.error("Error generating JPEG image:", error);
    return null;
  }
};
