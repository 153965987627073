import { WIDGET_METRIC_GROUPS } from "@services/math/math.const";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import Arrow from "@assets/icons/Arrow";
import { useCanvaStore } from "src/store/canva/canva.state";
import { MetricCard } from "@pages/CROModule/components/CROPage/components/Sections/MetricBuilder/MetricCard";
import { Widget } from "@pages/Dashboard/features/Widgets/Widget";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { WidgetClass } from "@services/widget.service";
import { endOfWeek, startOfWeek, subWeeks } from "date-fns";
import Setting from "@assets/icons/setting";
import classNames from "classnames";
import { Spin } from "antd";
import "../modal.scss";
import { SettingsModal } from "../SettingsSmallModal";

export const AdvancedView: React.FC = () => {
  const [openedAccordion, setOpenedAccordion] = useState<string[]>([]);
  const [openGroupModal, setOpenGroupModal] = useState<string | null>(null);

  const { isWhatIf } = useCanvaStore();
  const { dateRange, compareType, availableWidgets } = useDashboardStore();
  const { compareWith } = useAdsAnaliticsStore();
  const { startDate, endDate } = dateRange;
  const [widgets, setWidgets] = useState<IWidget[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedWidgets, setSelectedWidgets] =
    useState<string[]>(availableWidgets);
  const [choosedWidgets, setChoosedWidgets] = useState(selectedWidgets);

  useEffect(() => {
    const fetchWidgets = async () => {
      setIsLoading(true);
      const widgetClass = new WidgetClass();
      const startDateString = startDate.toISOString().split("T")[0];
      const endDateString = endDate.toISOString().split("T")[0];
      const { startDate: compareStartDate, endDate: compareEndDate } =
        compareWith;
      const compareStartDateString = startOfWeek(subWeeks(compareStartDate, 1))
        .toISOString()
        .split("T")[0];
      const compareEndDateString = endOfWeek(subWeeks(compareEndDate, 1))
        .toISOString()
        .split("T")[0];

      const response = await widgetClass.getWidgetsData(
        selectedWidgets,
        startDateString,
        endDateString,
        compareStartDateString,
        compareEndDateString,
        compareType,
      );
      setWidgets(response);
      setIsLoading(false);
    };
    fetchWidgets();
  }, [selectedWidgets, dateRange, compareWith, compareType]);

  const toggleAccordion = (key: string) => {
    if (openedAccordion === null) setOpenedAccordion([key]);
    else if (openedAccordion?.includes(key))
      setOpenedAccordion(openedAccordion.filter((k) => k !== key));
    else setOpenedAccordion([...openedAccordion, key]);
  };

  const resetToDefault = () => {
    setChoosedWidgets(selectedWidgets);
  };

  const onApply = () => {
    setSelectedWidgets(choosedWidgets);
    setOpenGroupModal(null);
  };

  if (isLoading) return <Spin />;

  return (
    <div className={styles.container}>
      {WIDGET_METRIC_GROUPS.map((group) => (
        <div
          className={classNames(
            styles.accordion,
            openedAccordion?.includes(group.title) && styles.open,
          )}
        >
          <button
            className={styles.accordion__title}
            onClick={() => toggleAccordion(group.title)}
          >
            <div className={styles.name}>
              <Arrow />
              {group.title}
            </div>
            <button
              className={styles.setting}
              onClick={(e) => {
                e.stopPropagation();
                setOpenGroupModal(group.title);
              }}
            >
              <Setting />
            </button>
          </button>
          <div className={styles.accordion__content}>
            {group.keys.map((metric) => {
              const widget = widgets?.find((w) => w.id === metric);
              if (!widget) return null;
              return isWhatIf ? (
                <MetricCard key={metric} metric={widget} />
              ) : (
                <Widget
                  key={metric}
                  widget={widget}
                  additionalClass={styles.widget}
                />
              );
            })}
          </div>
          <SettingsModal
            open={openGroupModal === group.title}
            title={group.title}
            availableKeys={group.keys}
            selectedKeys={choosedWidgets}
            onClose={() => setOpenGroupModal(null)}
            onChange={(keys) => setChoosedWidgets(keys)}
            resetToDefault={resetToDefault}
            onApply={onApply}
          />
        </div>
      ))}
    </div>
  );
};
