import {
  campaignsColumns,
  campaignsData,
  targetData,
  targetingColumns,
} from "./mock";
import { WhatIfTab } from "./Tabs";
import {
  adtypeData,
  formatData,
  funnelData,
  matchColumns,
  matchData,
  productsColumns,
  targetingData as targetTypeData,
} from "../../mock";
import { horizontalTargetData } from "@pages/AdsAnalitics/components/SanKey/Chart/chart.mock";
import { IPerformanceTableData } from "@services/canvas/canvas.api";
import { performanceColumns } from "../CampaignTable/columns";

export const getSelectData = (
  sankeyTab: WhatIfTab,
  performanceData: IPerformanceTableData[],
) => {
  let data;
  let colName;
  switch (sankeyTab) {
    case "Campaign":
      data = campaignsData;
      colName = "campaign";
      break;
    case "Ad Type":
      data = adtypeData;
      break;
    case "Funnel":
      data = funnelData;
      break;
    case "Target Type":
      data = horizontalTargetData;
      break;
    case "Performance":
      data = performanceData;
      colName = "performance";
      break;
    case "Targeting":
      data = targetData;
      colName = "targeting";
      break;
  }

  return { data, colName };
};

export const getColumns = (type: WhatIfTab) => {
  switch (type) {
    case "Campaign":
      return campaignsColumns;
    case "Ad Type":
      return productsColumns;
    case "Funnel":
      return productsColumns;
    case "Target Type":
      return productsColumns;
    case "Performance":
      return performanceColumns;
    case "Targeting":
      return targetingColumns;
    case "Match type":
      return matchColumns;
    default:
      return [];
  }
};

export const getData = (
  type: WhatIfTab,
  performanceData?: IPerformanceTableData[],
) => {
  let data = [];
  switch (type) {
    case "Campaign":
      data = campaignsData;
      break;
    case "Performance":
      data = performanceData;
      break;
    case "Targeting":
      data = targetData;
      break;
    case "Ad Type":
      data = adtypeData;
      break;
    case "Funnel":
      data = funnelData;
      break;
    case "Target Type":
      data = targetTypeData;
      break;
    case "Match type":
      data = matchData;
      break;
    default:
      return [];
  }

  return formatData({ data });
};
