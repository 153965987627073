import { Select } from "@components/Select";
import styles from "./styles.module.scss";
import { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
} from "recharts";

const series = [
  {
    name: "Green Part",
    color: "#2ECC71",
    data: [
      { category: 100, value: 0 },
      { category: 120, value: 9000 },
      { category: 140, value: 13000 },
      { category: 150, value: 15000 },
    ],
  },
  {
    name: "Yellow Part",
    color: "#F1C40F",
    data: [
      { category: 150, value: 15000 },
      { category: 160, value: 16000 },
      { category: 180, value: 18000 },
      { category: 200, value: 20000 },
    ],
  },
  {
    name: "Red Part",
    color: "#E74C3C",
    data: [
      { category: 200, value: 20000 },
      { category: 220, value: 21000 },
      { category: 250, value: 24000 },
      { category: 300, value: 28000 },
    ],
  },
];

export const SaturationCurve = () => {
  const [choosedCurve, setChoosedCurve] = useState("Ad Sales");

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Saturation Curve</h3>
        <div className={styles.select}>
          <Select
            options={[
              "Ad Sales",
              "Ad Sales Contribution Profit",
              "Organic Sales",
              "Organic Sales Contribution Profit",
              "Total Sales",
              "Total Sales Contribution Profit",
            ]}
            value={choosedCurve}
            onChange={(value) => setChoosedCurve(value)}
            placeholder="Select a curve"
            className={styles.select__dropdown}
          />
        </div>
      </div>
      <div className={styles.chart}>
        <LineChart
          width={1160}
          height={428}
          margin={{ top: 0, right: 0, bottom: 40, left: 0 }}
        >
          <CartesianGrid stroke="#1F1F20" />
          <XAxis
            dataKey="category"
            type="number"
            domain={[100, 300]}
            ticks={[100, 150, 200, 250, 300]}
          >
            <Label
              value="Spend"
              offset={-10}
              position="insideBottom"
              style={{ textAnchor: "middle", fill: "#fff", fontSize: 14 }}
            />
          </XAxis>
          <YAxis
            dataKey="value"
            domain={[0, 30000]}
            ticks={[0, 10000, 20000, 30000]}
            tickFormatter={(value) =>
              `$${value >= 1000 ? value / 1000 + "k" : value}`
            }
          />
          <Tooltip />
          {series.map((s) => (
            <Line
              key={s.name}
              dataKey="value"
              data={s.data}
              name={s.name}
              stroke={s.color}
              type="monotone"
              dot={false}
              connectNulls
              strokeWidth={3}
            />
          ))}
        </LineChart>
      </div>
    </div>
  );
};
