import { Api } from "src/utils/api";
import { MATH_BASE_URL } from "./mathUrl";
import { WIDGET_METRIC_KEYS } from "./math.const";

export type WidgetMetrics = Record<(typeof WIDGET_METRIC_KEYS)[number], number>;

export type WidgetMetricKey = keyof WidgetMetrics;

export class MathService extends Api {
  public static instance: MathService;

  public constructor() {
    super(MATH_BASE_URL);
  }

  private static getInstance(): MathService {
    if (!MathService.instance) {
      MathService.instance = new MathService();
    }
    return MathService.instance;
  }

  public static async getSalesTotal(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get("/sales/total", {
      date_start,
      date_end,
    });
    return data;
  }

  public static async getAov(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get("/aov", {
      date_start,
      date_end,
    });
    return data;
  }

  public static async getAdsImpressions(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get(
      "/ads-sales/impressions",
      {
        date_start,
        date_end,
      },
    );
    return data;
  }

  public static async getAdsClicks(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get(
      "/ads-sales/clicks",
      {
        date_start,
        date_end,
      },
    );
    return data;
  }

  public static async getAdsOrders(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get(
      "/ads-sales/orders",
      {
        date_start,
        date_end,
      },
    );
    return data;
  }

  public static async getAdsUnitsSold(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get(
      "/ads-sales/units-sold",
      {
        date_start,
        date_end,
      },
    );
    return data;
  }

  public static async getAdsSales(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get(
      "/ads-sales/sales",
      {
        date_start,
        date_end,
      },
    );
    return data;
  }

  public static async getAdsSpend(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get(
      "/ads-sales/spend",
      {
        date_start,
        date_end,
      },
    );
    return data;
  }

  public static async getAdsAcos(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get(
      "/ads-sales/acos",
      {
        date_start,
        date_end,
      },
    );
    return data;
  }

  public static async getAdsRoas(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get(
      "/ads-sales/roas",
      {
        date_start,
        date_end,
      },
    );
    return data;
  }

  public static async getAdsCpc(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get("/ads-sales/cpc", {
      date_start,
      date_end,
    });
    return data;
  }

  public static async getAdsCvr(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get("/ads-sales/cvr", {
      date_start,
      date_end,
    });
    return data;
  }

  public static async getAdsCtr(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get("/ads-sales/ctr", {
      date_start,
      date_end,
    });
    return data;
  }

  // Organic Metrics
  public static async getOrganicImpressions(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get(
      "/search-query/organic/impressions",
      {
        date_start,
        date_end,
      },
    );
    return data;
  }

  public static async getOrganicClicks(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get(
      "/search-query/organic/clicks",
      {
        date_start,
        date_end,
      },
    );
    return data;
  }

  public static async getOrganicCartAdds(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get(
      "/search-query/organic/cart-adds",
      {
        date_start,
        date_end,
      },
    );
    return data;
  }

  public static async getOrganicUnitsSold(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get(
      "/search-query/organic/units-sold",
      {
        date_start,
        date_end,
      },
    );
    return data;
  }

  public static async getOrganicCvr(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get(
      "/search-query/organic/cvr",
      {
        date_start,
        date_end,
      },
    );
    return data;
  }

  public static async getOrganicCtr(
    date_start: string,
    date_end: string,
  ): Promise<number> {
    const data: number = await MathService.getInstance().get(
      "/search-query/organic/ctr",
      {
        date_start,
        date_end,
      },
    );
    return data;
  }

  public async getAllMetrics(
    date_start: string,
    date_end: string,
  ): Promise<WidgetMetrics> {
    const [
      aov,
      salesTotal,
      adsImpressions,
      adsClicks,
      adsOrders,
      adsUnitsSold,
      adsSales,
      adsSpend,
      adsAcos,
      adsRoas,
      adsCpc,
      adsCvr,
      adsCtr,
      organicImpressions,
      organicClicks,
      organicCartAdds,
      organicUnitsSold,
      organicCvr,
      organicCtr,
    ] = await Promise.all([
      MathService.getAov(date_start, date_end),
      MathService.getSalesTotal(date_start, date_end),
      MathService.getAdsImpressions(date_start, date_end),
      MathService.getAdsClicks(date_start, date_end),
      MathService.getAdsOrders(date_start, date_end),
      MathService.getAdsUnitsSold(date_start, date_end),
      MathService.getAdsSales(date_start, date_end),
      MathService.getAdsSpend(date_start, date_end),
      MathService.getAdsAcos(date_start, date_end),
      MathService.getAdsRoas(date_start, date_end),
      MathService.getAdsCpc(date_start, date_end),
      MathService.getAdsCvr(date_start, date_end),
      MathService.getAdsCtr(date_start, date_end),
      MathService.getOrganicImpressions(date_start, date_end),
      MathService.getOrganicClicks(date_start, date_end),
      MathService.getOrganicCartAdds(date_start, date_end),
      MathService.getOrganicUnitsSold(date_start, date_end),
      MathService.getOrganicCvr(date_start, date_end),
      MathService.getOrganicCtr(date_start, date_end),
    ]);

    return {
      aov,
      salesTotal,
      adsImpressions,
      adsClicks,
      adsOrders,
      adsUnitsSold,
      adsSales,
      adsSpend,
      adsAcos,
      adsRoas,
      adsCpc,
      adsCvr,
      adsCtr,
      organicImpressions,
      organicClicks,
      organicCartAdds,
      organicUnitsSold,
      organicCvr,
      organicCtr,
    };
  }

  // public static async getTotalSales(
  //   date_start: string,
  //   date_end: string,
  // ): Promise<number> {
  //   const data: number = await MathService.getInstance().get(
  //     "/sales-performance/total-sales",
  //     {
  //       date_start,
  //       date_end,
  //     },
  //   );

  //   useMathStore.getState().setSalesTotal(data);
  // }
}
