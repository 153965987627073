import WidgetsList from "@pages/Dashboard/features/Widgets/WidgetsList";
import styles from "./styles.module.scss";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useEffect, useState } from "react";
import MetricBuilder from "@pages/CROModule/components/CROPage/components/Sections/MetricBuilder";
import { useCanvaStore } from "src/store/canva/canva.state";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { WidgetClass } from "@services/widget.service";
import { endOfWeek, startOfWeek, subWeeks } from "date-fns";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { WidgetMetricKey } from "@services/math/math.service";
import { Spin } from "antd";
import { Widget } from "@pages/Dashboard/features/Widgets/Widget";

interface IDefaultViewProps {
  choosenWidgets: WidgetMetricKey[];
}

export const DefaultView: React.FC<IDefaultViewProps> = ({
  choosenWidgets,
}) => {
  const { dateRange, compareType } = useDashboardStore();
  const { compareWith } = useAdsAnaliticsStore();
  const { startDate, endDate } = dateRange;
  const [widgets, setWidgets] = useState<IWidget[]>();
  const [isLoading, setIsLoading] = useState(true);
  const { isWhatIf } = useCanvaStore();

  useEffect(() => {
    const fetchWidgets = async () => {
      setIsLoading(true);
      const widgetClass = new WidgetClass();
      const startDateString = startDate.toISOString().split("T")[0];
      const endDateString = endDate.toISOString().split("T")[0];
      const { startDate: compareStartDate, endDate: compareEndDate } =
        compareWith;
      const compareStartDateString = startOfWeek(subWeeks(compareStartDate, 1))
        .toISOString()
        .split("T")[0];
      const compareEndDateString = endOfWeek(subWeeks(compareEndDate, 1))
        .toISOString()
        .split("T")[0];

      const response = await widgetClass.getWidgetsData(
        choosenWidgets,
        startDateString,
        endDateString,
        compareStartDateString,
        compareEndDateString,
        compareType,
      );
      setWidgets(response);
      setIsLoading(false);
    };
    fetchWidgets();
  }, [choosenWidgets, dateRange, compareWith, compareType]);

  return isWhatIf ? (
    <div className={styles.optimisation}>
      <MetricBuilder metricPerRow={5} useConfigButton hideSettings hideLine />
    </div>
  ) : (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loading}>
          <Spin />
        </div>
      ) : (
        <div className={styles.widgets}>
          {widgets.map((widget, index) => (
            <Widget
              key={index}
              widget={widget}
              additionalClass={styles.widget}
            />
          ))}
        </div>
      )}
    </div>
  );
};
