import styles from "./styles.module.scss";
import InputFile from "../InputFile";
import { MouseEventHandler, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import SwiperCore from "swiper";
import { Search } from "lucide-react";
import { Spin } from "antd";
import classNames from "classnames";
import { Platform } from "../../types";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./swiper.scss";

SwiperCore.use([Navigation]);

const AssetsTab = ({
  items,
  onChange,
  onUploadImage,
  isLoading = false,
}: {
  items: Platform[];
  onChange: (target: HTMLElement) => void;
  onUploadImage: (file: File | null) => void;
  isLoading?: boolean;
}) => {
  const addDraggableImage: MouseEventHandler<HTMLDivElement> = (event) => {
    const target = event.target as HTMLElement | null;

    if (target === null) {
      return;
    }

    const newButton = target.closest(
      '[data-type="new-draggable"]',
    ) as HTMLElement | null;

    if (newButton === null) {
      return;
    }

    onChange(newButton);
  };

  return (
    <>
      <InputFile onChange={onUploadImage} />
      <label className="input">
        <div className="input__icon-wrapper">
          <Search size={15} stroke="#A6A7A8" className="input__icon" />
          <input
            className="input__input"
            type="text"
            placeholder="Search assets..."
          />
        </div>
      </label>
      <div
        className={classNames(styles.sliders, isLoading && styles.loading)}
        onClick={addDraggableImage}
      >
        {isLoading && <Spin size="large" className={styles.slidersLoading} />}
        <div className={styles.slider}>
          <div className={styles.slider__header}>
            <h4 className={styles.slider__label}>Platforms</h4>
            <button className={styles.slider__link}>Show more</button>
          </div>
          <Swiper
            watchSlidesProgress={true}
            loop
            navigation
            spaceBetween={8}
            slidesPerView={3}
          >
            {items.map((image, i) => (
              <SwiperSlide key={i}>
                <div
                  className={styles.image}
                  data-type="new-draggable"
                  style={{
                    backgroundImage: `url(data:image/png;base64,${image.original})`,
                  }}
                  data-image={image.original}
                  data-mask={image.mask}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default AssetsTab;
