import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactECharts from "echarts-for-react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { getOption, seriesConfig } from "./utils";
import { SanKey } from "@pages/AdsAnalitics/components/SanKey";
import { ArrowLeft } from "lucide-react";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { useCanvaStore } from "src/store/canva/canva.state";
import { ProductDataByAsin } from "@services/amazon/products/types";
import { AmazonService } from "@services/amazon/amazon.service";

export const FunnelChart: React.FC = () => {
  const [openedDetails, setOpenedDetails] = useState("");
  const [activeSeries, setActiveSeries] = useState({
    "Amazon Ads": true,
    "Amazon Organic": true,
    "Organic Social": false,
    "Other Sales": false,
  });
  const [hoveredSection, setHoveredSection] = useState<string | null>(null);
  const { adSlides } = useAdsAnaliticsStore((state) => ({
    adSlides: state.adSlides,
  }));
  const { asin } = useCanvaStore();
  const [productData, setProductData] = useState<ProductDataByAsin>();

  useEffect(() => {
    const fetchProductData = async () => {
      if (!asin) {
        return;
      }

      try {
        const res = await AmazonService.products.getProductDataByAsin(asin);
        setProductData(res);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductData();
  }, [asin]);

  const toggleSeries = (seriesName: string) => {
    setActiveSeries((prev) => ({
      ...prev,
      [seriesName]: !prev[seriesName],
    }));
  };

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const { offsetX } = event.nativeEvent;
      const width = event.currentTarget.clientWidth;

      const relativeX = offsetX / width;

      let section = null;
      if (relativeX < 0.33) {
        section = "left";
      } else if (relativeX < 0.66) {
        section = "center";
      } else {
        section = "right";
      }

      if (section !== hoveredSection) {
        setHoveredSection(section);
      }
    },
    [hoveredSection],
  );

  const handleMouseLeave = () => {
    setHoveredSection(null);
  };

  const handleFunnelClick = (params: any) => {
    if (params.componentType === "series") {
      setOpenedDetails(params.name);
    }
  };

  const options = useMemo(() => {
    return getOption(activeSeries, adSlides, productData);
  }, [activeSeries, adSlides, productData]);

  if (openedDetails) {
    return (
      <div className={styles.sankey}>
        <button
          className={styles.back}
          onClick={() => {
            setOpenedDetails("");
          }}
        >
          <ArrowLeft size={20} />
          <span>{openedDetails} Sankey</span>
        </button>
        <SanKey
          hideTree
          hideTitle
          hideAsin
          isFunnelChart
          defaultOpenTab="Funnel"
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h3>Funnel Performance</h3>
      <div className={styles.toggleButtons}>
        {seriesConfig.map((series, index) => (
          <button
            key={series.name}
            className={classNames(styles.toggleButton, {
              [styles.active]: activeSeries[series.name],
            })}
            style={{ backgroundColor: series.color }}
            onClick={() => toggleSeries(series.name)}
            disabled={index > 1 && !activeSeries[series.name]}
          >
            <span className={styles.indicator} />
            {series.name}
          </button>
        ))}
      </div>
      <div
        className={styles.chart__container}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <div className={styles.legend}>
          {seriesConfig.map((series, index) => {
            if (index < 2) {
              return (
                <div
                  key={series.name}
                  className={classNames(styles.legendItem)}
                  onClick={() => toggleSeries(series.name)}
                >
                  <span
                    className={styles.indicator}
                    style={{ backgroundColor: series.color }}
                  />
                  {series.name}
                </div>
              );
            }
          })}
        </div>
        <ReactECharts
          option={options}
          onEvents={{
            click: (params: any) => {
              handleFunnelClick(params);
            },
          }}
          style={{
            height: "538px",
            width: "1252px",
            left: "-54px",
          }}
        />
        <div className={styles.overlay}>
          <div
            className={classNames(
              styles.section,
              styles.left,
              hoveredSection && hoveredSection !== "left" && styles.dimmed,
            )}
          ></div>
          <div
            className={classNames(
              styles.section,
              styles.center,
              hoveredSection && hoveredSection !== "center" && styles.dimmed,
            )}
          ></div>
          <div
            className={classNames(
              styles.section,
              styles.right,
              hoveredSection && hoveredSection !== "right" && styles.dimmed,
            )}
          ></div>
        </div>
      </div>
    </div>
  );
};
