import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface MasksModalProps {
  imgs?: any;
  show: boolean;
  setShow: (show: boolean) => void;
  setActiveMask: (mask: { image: string; mask: string }) => void;
}

const MasksModal = ({
  imgs,
  show,
  setShow,
  setActiveMask,
}: MasksModalProps) => {
  const [highlightedMask, setHighlightedMask] = useState<string | null>(null);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);

  useEffect(() => {
    if (imgs.length > 0) {
      setActiveMask(imgs[0]);
      setHighlightedMask(imgs[0].mask);
    }
  }, [imgs, setActiveMask]);

  const closeModal = () => {
    setShow(false);
  };

  const changeMask = (mask: { image: string; mask: string }) => {
    setActiveMask(mask);
    setHighlightedMask(mask.mask);
  };

  const handleSelect = () => {
    if (highlightedMask) {
      closeModal();
    }
  };

  return (
    <div className={classNames(styles.wrapper, { [styles.active]: show })}>
      <div className={styles.modal}>
        <span className={styles.close} onClick={closeModal}>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.698 15.7673C17.1005 16.1636 17.1005 16.8063 16.698 17.2027C16.2956 17.5991 15.6431 17.5991 15.2407 17.2027L10.7286 12.7583C10.3262 12.3619 9.67369 12.3619 9.27126 12.7583L4.75913 17.2027C4.35671 17.5991 3.70425 17.5991 3.30182 17.2027C2.89939 16.8063 2.89939 16.1636 3.30182 15.7673L9.27126 9.88739C9.67369 9.491 10.3262 9.491 10.7286 9.88739L16.698 15.7673Z"
              fill="white"
              style={{ fill: "white", fillOpacity: 1 }}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.30198 5.23274C2.89955 4.83635 2.89955 4.19368 3.30198 3.79729C3.7044 3.4009 4.35686 3.4009 4.75929 3.79729L9.27142 8.24172C9.67385 8.63811 10.3263 8.63811 10.7287 8.24172L15.2409 3.79729C15.6433 3.4009 16.2958 3.4009 16.6982 3.79729C17.1006 4.19368 17.1006 4.83635 16.6982 5.23274L10.7287 11.1126C10.3263 11.509 9.67385 11.509 9.27142 11.1126L3.30198 5.23274Z"
              fill="white"
              style={{ fill: "white", fillOpacity: 1 }}
            />
          </svg>
        </span>
        <h2>Select mask</h2>
        <div className={styles.row}>
          {Array.isArray(imgs) &&
            imgs.map((img, index) => (
              <div
                key={index}
                className={classNames(styles.imgContainer, {
                  [styles.active]: highlightedMask === img.mask,
                })}
                onClick={() => changeMask(img)}
              >
                <img
                  src={`data:image/png;base64,${img.image}`}
                  alt="mask"
                  className={styles.img}
                />
              </div>
            ))}
        </div>
        <button className="button" onClick={handleSelect}>
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.5 11.5L8.83333 16.5L19.5 6.5"
              stroke="white"
              style={{ stroke: "white", strokeOpacity: 1 }}
              strokeWidth="2.4"
            />
          </svg>
          Select
        </button>
      </div>
    </div>
  );
};

export default MasksModal;
