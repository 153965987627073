export const performanceColumns = [
  {
    title: "Type Ad",
    dataIndex: "performance",
    key: "performance",
    width: 500,
  },
  {
    title: "Ads Sales",
    dataIndex: "sales",
    key: "sales",
  },
  {
    title: "Ads Spend",
    dataIndex: "spend",
    key: "spend",
  },
  {
    title: "Acos",
    dataIndex: "acos",
    key: "acos",
  },
  {
    title: "Cpc",
    dataIndex: "cpc",
    key: "cpc",
  },
];
