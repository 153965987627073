import { WidgetMetricKey } from "./math.service";

export const WIDGET_METRIC_KEYS = [
  // ----- TOTAL -----
  "totalSales",
  "totalSpend",
  "totalImpressions",
  "totalClicks",
  "totalUnitsSold",
  "totalCtr",
  "totalCvr",
  "totalOrders",
  "totalNtbOrders",

  // ----- AD -----
  "adsSales",
  "adsSpend",
  "adsImpressions",
  "adsClicks",
  "adsUnitsSold",
  "adsCtr",
  "adsCvr",
  "adsOrders",
  "adsAcos",
  "adsRoas",
  "adsCpc",

  // ----- ORGANIC -----
  "organicSales",
  "organicSpend",
  "organicImpressions",
  "organicClicks",
  "organicUnitsSold",
  "organicCtr",
  "organicCvr",
  "organicOrders",

  // ----- CFO -----
  "availableCapital",
  "frozenCapital",
  "borrowedCapital",
  "lostSales",
  "grossMargin",
  "opex",
  "contribution",
  "profitEbitda",

  // ----- COO -----
  "fbaInStockRate",
  "fbtInStockRate",
  "3plInStockRate",
  "storageCosts",
  "transportCost",
  "safetyStock",
  "shippingCosts",
  "forecastingAccuracy",
  "contributionMargin",
  "inventoryTurnover",
  "lostSalesCfo",

  //--finance--
  "Available Capital",
  "Borrowed Capital",
  "Lost Sales",
  "Gross Margin",
  "OPEX",
  "Contribution Profit",
  "EBITDA",
  //----supply---
  "Transport Cost ",
  "Safety Stock",
  "Shipping Costs ",
  "Storage Costs",
  "Forecasting Accuracy",
  "Inventory Turnover",
  "Lost Sales",
  "Contribution Margin",
];

type WidgetConfig = Record<
  (typeof WIDGET_METRIC_KEYS)[number],
  { label: string; format: string }
>;

export const WIDGET_METRICS_CONFIG: WidgetConfig = {
  // ----- TOTAL -----
  totalSales: {
    label: "Total Sales",
    format: "currency",
  },
  totalSpend: {
    label: "Total Spend",
    format: "currency",
  },
  totalImpressions: {
    label: "Total Impressions",
    format: "number",
  },
  totalClicks: {
    label: "Total Clicks",
    format: "number",
  },
  totalUnitsSold: {
    label: "Total Units Sold",
    format: "number",
  },
  totalCtr: {
    label: "Total CTR",
    format: "percentage",
  },
  totalCvr: {
    label: "Total CVR",
    format: "percentage",
  },
  totalOrders: {
    label: "Total Orders",
    format: "number",
  },
  totalNtbOrders: {
    label: "Total NTB Orders",
    format: "number",
  },

  // ----- AD -----
  adsSales: {
    label: "Ad Sales",
    format: "currency",
  },
  adsSpend: {
    label: "Ad Spend",
    format: "currency",
  },
  adsImpressions: {
    label: "Ad Impressions",
    format: "number",
  },
  adsClicks: {
    label: "Ad Clicks",
    format: "number",
  },
  adsUnitsSold: {
    label: "Ad Units Sold",
    format: "number",
  },
  adsCtr: {
    label: "Ad CTR",
    format: "percentage",
  },
  adsCvr: {
    label: "Ad CVR",
    format: "percentage",
  },
  adsOrders: {
    label: "Ad Orders",
    format: "number",
  },
  adsAcos: {
    label: "Ad ACOS",
    format: "percentage",
  },
  adsRoas: {
    label: "Ad ROAS",
    format: "number",
  },
  adsCpc: {
    label: "Ad CPC",
    format: "currency",
  },

  // ----- ORGANIC -----
  organicSales: {
    label: "Organic Sales",
    format: "currency",
  },
  organicSpend: {
    label: "Organic Spend",
    format: "currency",
  },
  organicImpressions: {
    label: "Organic Impressions",
    format: "number",
  },
  organicClicks: {
    label: "Organic Clicks",
    format: "number",
  },
  organicCartAdds: {
    label: "Organic Cart Adds",
    format: "number",
  },
  organicUnitsSold: {
    label: "Organic Units Sold",
    format: "number",
  },
  organicCtr: {
    label: "Organic CTR",
    format: "percentage",
  },
  organicCvr: {
    label: "Organic CVR",
    format: "percentage",
  },
  organicOrders: {
    label: "Organic Orders",
    format: "number",
  },

  // ----- CFO -----
  availableCapital: {
    label: "Available Capital",
    format: "currency",
  },
  frozenCapital: {
    label: "Frozen Capital",
    format: "currency",
  },
  borrowedCapital: {
    label: "Borrowed Capital",
    format: "currency",
  },
  lostSales: {
    label: "Lost Sales",
    format: "currency",
  },
  grossMargin: {
    label: "Gross Margin",
    format: "currency",
  },
  opex: {
    label: "OPEX",
    format: "currency",
  },
  contribution: {
    label: "Contribution",
    format: "currency",
  },
  profitEbitda: {
    label: "Profit EBITDA",
    format: "currency",
  },

  // ----- CFO -----
  fbaInStockRate: {
    label: "FBA In Stock Rate",
    format: "percentage",
  },
  fbtInStockRate: {
    label: "FBT In Stock Rate",
    format: "percentage",
  },
  "3plInStockRate": {
    label: "3PL In Stock Rate",
    format: "percentage",
  },
  shippingCosts: {
    label: "Shipping Costs",
    format: "currency",
  },
  transportCost: {
    label: "Transport Cost",
    format: "currency",
  },
  safetyStock: {
    label: "Safety Stock",
    format: "number",
  },
  lostSalesCfo: {
    label: "Lost Sales (CFO)",
    format: "currency",
  },
  storageCosts: {
    label: "Storage Costs",
    format: "currency",
  },
  forecastingAccuracy: {
    label: "Forecasting Accuracy",
    format: "percentage",
  },
  contributionMargin: {
    label: "Contribution Margin",
    format: "percentage",
  },
  inventoryTurnover: {
    label: "Inventory Turnover",
    format: "number",
  },
};

export const WIDGET_METRIC_GROUPS = [
  {
    title: "Total",
    keys: [
      "totalSales",
      "totalSpend",
      "totalImpressions",
      "totalClicks",
      "totalUnitsSold",
      "totalCtr",
      "totalCvr",
      "totalOrders",
      "totalNtbOrders",
    ] as WidgetMetricKey[],
  },
  {
    title: "Ad",
    keys: [
      "adsSales",
      "adsSpend",
      "adsImpressions",
      "adsClicks",
      "adsUnitsSold",
      "adsCtr",
      "adsCvr",
      "adsOrders",
      "adsAcos",
      "adsRoas",
      "adsCpc",
    ] as WidgetMetricKey[],
  },
  {
    title: "Organic",
    keys: [
      "organicSales",
      "organicSpend",
      "organicImpressions",
      "organicClicks",
      "organicUnitsSold",
      "organicCtr",
      "organicCvr",
      "organicOrders",
      "organicCartAdds",
    ] as WidgetMetricKey[],
  },
  {
    title: "COO",
    keys: [
      "fbaInStockRate",
      "fbtInStockRate",
      "3plInStockRate",
      "shippingCosts",
      "transportCost",
      "safetyStock",
      "storageCosts",
      "forecastingAccuracy",
      "contributionMargin",
      "inventoryTurnover",
      "lostSalesCfo",
    ] as WidgetMetricKey[],
  },
  {
    title: "CFO",
    keys: [
      "availableCapital",
      "frozenCapital",
      "borrowedCapital",
      "lostSales",
      "grossMargin",
      "opex",
      "contribution",
      "profitEbitda",
    ] as WidgetMetricKey[],
  },
];
