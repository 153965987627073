import { useState } from "react";
import styles from "./styles.module.scss";
import { Switch } from "antd";
import Setting from "@assets/icons/setting";
import { AdvancedView } from "./AdvancedView";
import { DefaultView } from "./DefaultView";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { WidgetMetricKey } from "@services/math/math.service";
import { SettingsModal } from "./SettingsBigModal";

const MAXIMUM_WIDGETS = 10;

export const ExecutiveSummary: React.FC = () => {
  const [isAdvancedView, setIsAdvancedView] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { selectedWidgets, setSelectedWidgets } = useDashboardStore();

  const [choosedWidgets, setChoosedWidgets] =
    useState<WidgetMetricKey[]>(selectedWidgets);

  const onApply = () => {
    setSelectedWidgets(choosedWidgets);
    setIsOpenModal(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Executive Summary</h3>
        <div className={styles.switch__container}>
          <Switch onChange={() => setIsAdvancedView(!isAdvancedView)} />
          Advanced View
        </div>
        {!isAdvancedView && (
          <button
            className={styles.setting__button}
            onClick={() => setIsOpenModal(true)}
          >
            <Setting />
          </button>
        )}
      </div>
      {isAdvancedView ? (
        <AdvancedView />
      ) : (
        <DefaultView choosenWidgets={selectedWidgets} />
      )}
      <SettingsModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onApply={onApply}
        choosedWidgets={choosedWidgets}
        setChoosedWidgets={setChoosedWidgets}
        maxWidgets={MAXIMUM_WIDGETS}
      />
    </div>
  );
};
