import { Navigate, RouteObject } from "react-router-dom";
import { ADS_ANALITICS_ROUTES, ROUTES } from "./routes";
import { Auth } from "@pages/Auth/Auth";
import { PrivatePage } from "@pages/PrivatePage/PrivatePage";
import { Login } from "@pages/Auth/Login/Login";
import { Signup } from "@pages/Auth/Signup/Signup";
import { VerifyProvider } from "@pages/Auth/VerifyProvider";
import { AmazonOnboarding } from "@pages/AmazonOnboarding/AmazonOnboarding.tsx";
import { Dashboard } from "@pages/Dashboard";
import { DocumentPage } from "@pages/document/DocumentPage.tsx";
import FreeFormWriting from "@pages/free-form-writing/FreeFormWriting.tsx";
import { SettingsPage } from "@pages/settings";
import { AiTemplates } from "@pages/ai-templates";
import { ImageEnhancePage } from "@pages/ImageEnhancePage";
import { RemoveBackgroundPage } from "@pages/RemoveBackgroundPage";
import { NotFoundPage } from "@pages/NotFoundPage";
import { AmazonOnboardingComplete } from "@pages/AmazonOnboardingComplete/AmazonOnboardingComplete.tsx";
import { Overview } from "@pages/Overview";
import SuccessfulRegistration from "@pages/Auth/Signup/SuccessfulRegistration";
import ForgotPassword from "@pages/Auth/Login/ForgotPassword";
import Activate from "@pages/Activate/Activate";
import ConfirmedEmail from "@pages/ConfirmedEmail";
import { ConfirmPasswordReset } from "@pages/Auth/ConfirmPasswordReset";
import ConfirmedPassword from "@pages/ConfirmedPassword";
import Welcome from "@pages/Welcome";
import Compare from "@pages/Compare";
import ConnectAmazon from "@pages/ConnectAmazon";
import RedirectToSeller from "@pages/RedirectToSeller";
import RedirectToAmazon from "@pages/RedirectToAmazon";
import CongratulationConnect from "@pages/CongratulationConnect";
import ReferralPage from "@pages/ReferralPage";
import CROModule from "@pages/CROModule";
import PhotoOptimisation from "@pages/CROModule/components/Product/Optimisation/optimisationPages/PhotoOptimisation";
import OptimisationDetails from "@pages/CROModule/components/Product/Optimisation/optimisationPages/OptimisationDetails";
import PhotoUpdate from "@pages/CROModule/components/Product/Optimisation/optimisationPages/PhotoOptimisation/PhotoOptimizationUpdate";
import PhotoOptimisationDetails from "@pages/CROModule/components/Product/Optimisation/optimisationPages/PhotoOptimisation/PhotoOptimisationDetails";
import VideoOptimisation from "@pages/CROModule/components/Product/Optimisation/optimisationPages/VideoOptimisation";
import CROPage from "@pages/CROModule/components/CROPage";
import { AdsAnalitics } from "@pages/AdsAnalitics";
import { SanKey } from "@pages/AdsAnalitics/components/SanKey";
import { Campaigns } from "@pages/AdsAnalitics/components/Campaigns";
import { Customers } from "@pages/Customers";
import { SubscriptionPerformancePage } from "@pages/SubscriptionPerformance/components";
import { CanvasEditor } from "@pages/CanvasEditor";
import { Workflows } from "@pages/Worflows";
import { Finance } from "@pages/Finance";
import { SupplyChain } from "@pages/SupplyChain";

export const routes: RouteObject[] = [
  {
    path: `${ROUTES.AUTH}/*`,
    element: <Auth />,
    children: [
      {
        path: "",
        element: <Navigate to={ROUTES.AUTH_LOGIN} />,
      },
      {
        path: ROUTES.AUTH_LOGIN,
        element: <Login />,
      },
      {
        path: ROUTES.AUTH_FORGOT,
        element: <ForgotPassword />,
      },
      {
        path: ROUTES.AUTH_SIGNUP,
        element: <Signup />,
      },
      {
        path: ROUTES.AUTH_SIGNUPSUCCESSFUL,
        element: <SuccessfulRegistration />,
      },
      {
        path: ROUTES.AUTH_CALLBACK,
        element: <VerifyProvider />,
      },
    ],
  },
  {
    path: `${ROUTES.AUTH_ACTIVATE}/*`,
    element: <Activate />,
  },
  {
    path: `${ROUTES.REDIRECT_TO_SELLER}/*`,
    element: <RedirectToSeller />,
  },
  {
    path: `${ROUTES.AUTH_CONFIRMED}/*`,
    element: <ConfirmedEmail />,
  },
  {
    path: `${ROUTES.AUTH_CONFIRMED}/*`,
    element: <ConfirmedEmail />,
  },
  {
    path: `${ROUTES.AUTH_PASSWORDCONFIRMED}/*`,
    element: <ConfirmedPassword />,
  },
  {
    path: `${ROUTES.AUTH_PASSWORDRESET}/:uid/:token`,
    element: <ConfirmPasswordReset />,
  },
  {
    path: "*",
    element: <PrivatePage />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: ROUTES.CUSTOMERS,
        element: <Customers />,
      },
      {
        path: "finance",
        element: <Finance />,
      },
      {
        path: "supply-chain",
        element: <SupplyChain />,
      },
      {
        path: ROUTES.COMPARE,
        element: <Compare />,
      },
      {
        path: ROUTES.MYPRODUCT,
        element: <Overview />,
      },
      {
        path: ROUTES.ADS_ANALYTICS,
        element: <AdsAnalitics />,
        children: [
          {
            path: ADS_ANALITICS_ROUTES.SANKEY,
            element: <SanKey />,
          },
          {
            path: ADS_ANALITICS_ROUTES.CAMPAIGNS,
            element: <Campaigns />,
          },
        ],
      },
      {
        path: `${ROUTES.SUBSCRIPTION_PERFORMANCE}`,
        element: <SubscriptionPerformancePage />,
      },
      {
        path: ROUTES.SHOW_ROOM,
        element: <Overview type="show-room" />,
      },
      {
        path: ROUTES.SHOW_ROOM_COMPARE,
        element: <Compare type="show-room" />,
      },
      {
        path: `${ROUTES.REFERRAL_PAGE}`,
        element: <ReferralPage />,
      },
      {
        path: ROUTES.WELCOME,
        element: <Welcome />,
      },
      {
        path: `${ROUTES.CONNECT_AMAZON}/*`,
        element: <ConnectAmazon />,
      },
      {
        path: "documents",
        element: <DocumentPage />,
      },
      {
        path: "free-form-writing/*",
        element: <FreeFormWriting />,
        children: [
          {
            path: ":documentId",
            element: <FreeFormWriting />,
          },
        ],
      },
      {
        path: ROUTES.CANVAS_EDITOR,
        element: <CanvasEditor />,
      },
      {
        path: ROUTES.WORKFLOWS,
        element: <Workflows />,
      },
      {
        path: `${ROUTES.CRO}/*`,
        element: <CROModule />,
        children: [
          {
            path: "",
            element: <CROPage />,
          },
          {
            path: ROUTES.CRO_OPTIMISATIONDETAINS,
            element: <OptimisationDetails />,
          },
          {
            path: ROUTES.CRO_PHOTOOPTIMISATION,
            element: <PhotoOptimisation />,
          },
          {
            path: ROUTES.CRO_VIDEOOPTIMISATION,
            element: <VideoOptimisation />,
          },
          {
            path: `${ROUTES.CRO_PHOTOOPTIMISATION}/:id`,
            element: <PhotoOptimisationDetails />,
          },
          {
            path: `${ROUTES.CRO_PHOTOUPDATE}/:id`,
            element: <PhotoUpdate />,
          },
        ],
      },
      {
        path: "settings/*",
        element: <SettingsPage />,
      },
      {
        path: "ai-templates/*",
        element: <AiTemplates />,
      },
      {
        path: "image-enhance/*",
        element: <ImageEnhancePage />,
      },
      {
        path: "remove-bg/*",
        element: <RemoveBackgroundPage />,
      },
      {
        path: "404",
        element: <NotFoundPage />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
  {
    path: "*",
    element: <PrivatePage hideHeader={true} />,
    children: [
      {
        path: ROUTES.AMAZON_ONBOARDING,
        element: <AmazonOnboarding />,
      },
      {
        path: ROUTES.AMAZON_ONBOARDING_COMPLETE,
        element: <AmazonOnboardingComplete />,
      },
      {
        path: `${ROUTES.REDIRECT_TO_AMAZON}/*`,
        element: <RedirectToAmazon />,
      },
      {
        path: `${ROUTES.CONGRATULATIONS_CONNECT}/*`,
        element: <CongratulationConnect />,
      },
    ],
  },
];
