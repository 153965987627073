import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./new-styles.module.scss";
import { toDataURLByFile } from "src/utils/toDataURL";
import {
  generateImageByPromptAndAsset,
  generateImageByPrompt,
  getPlatforms,
  getTemplates,
  removeBackground,
  getImageProductDescription,
} from "./api";
import { Platform, Template } from "./types";
import classNames from "classnames";
import Header from "./сomponents/Header";
import SettingsTab from "./сomponents/Settings";
import Nav from "./сomponents/Nav";
import AssetsTab from "./сomponents/Assets";
import AsinTab from "./сomponents/Asin";
import "./global.scss";
import Canvas from "./сomponents/Canvas";
import GenerateTab from "./сomponents/Generate";
import { useProductsStore } from "src/store/product.state";
import { AmazonService } from "@services/amazon/amazon.service";
import { useProductStore } from "src/store/overviewProduct.state";
import { navItems, SIZE_LARGE_IMAGE } from "./constants";
import MasksModal from "./сomponents/MasksModal";

const PhotoOptimisationDetails: React.FC = () => {
  const { productMaterials, setProductMaterials, productDataByAsin } =
    useProductsStore();
  const { selectedProduct } = useProductStore();
  const [templates, setTemplates] = useState<{
    original: Template[];
    transformed: Template[];
  }>({ original: [], transformed: [] });
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [activeNavIndex, setActiveNavIndex] = useState(0);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoadingAssetImage, setIsLoadingAssetImage] = useState(false);
  const [isLoadingImageGeneration, setLoadingImageGeneration] = useState(false);
  const [activeImage, setActiveImage] = useState<{
    mask: string;
    image: string;
  } | null>(null);
  const [activeAssetImage, setActiveAssetImage] = useState<{
    mask: string;
    image: string;
  } | null>(null);
  const [generatedImage, setGeneratedImage] = useState<string>("");
  const [customPrompt, setCustomPrompt] = useState<string>("");
  const [applyImg, setApplyImg] = useState(false);

  const [draggableAssets, setDraggableAssets] = useState<
    {
      image: string;
      style: CSSProperties;
    }[]
  >([]);
  const [activeBtn, setActiveBtn] = useState<HTMLElement | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [masks, setMasks] = useState<{ image: string; mask: string }[]>([]);
  const [formatCanvasResult, setFormatCanvasResult] = useState<string>("");

  const asinImages = useMemo(() => {
    return (
      productMaterials?.images
        ?.filter(
          (img) =>
            img.width >= SIZE_LARGE_IMAGE && img.height >= SIZE_LARGE_IMAGE,
        )
        .map((img) => img.link) ?? []
    );
  }, [productMaterials?.images]);

  useEffect(() => {
    const fetchProductData = async () => {
      const asin = selectedProduct?.asin;

      if (!asin) {
        return;
      }

      try {
        if (!productDataByAsin || productDataByAsin.asin !== asin) {
          await AmazonService.products.getProductDataByAsin([asin]);
        }

        if (!productMaterials || productMaterials.asin !== asin) {
          const [imagesData, videosData] = await Promise.all([
            AmazonService.products.getProductDataByAsinImgs([asin]),
            AmazonService.products.getProductDataByAsinVideos([asin]),
          ]);

          setProductMaterials({
            images: imagesData as any,
            videos: videosData as any,
            asin,
          });
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductData();
  }, [selectedProduct]);

  const handleActiveTab = (index: number) => {
    if (isLoadingImage || isLoadingAssetImage || isLoadingImageGeneration) {
      return;
    }
    setActiveNavIndex(index);
  };

  const handleChangeImage = async (index: number) => {
    const image = asinImages[index];

    setIsLoadingImage(true);
    resetGeneratedImage();

    try {
      const [maskAndImage, newImageProductDescription] = await Promise.all([
        removeBackground(image),
        getImageProductDescription(image),
      ]);
      setMasks(maskAndImage);
      setShowModal(true);

      setTemplates((prev) => ({
        ...prev,
        transformed: prev.original.map((t) => ({
          ...t,
          list: t.list.map((el) =>
            el.replace("[product]", newImageProductDescription),
          ),
        })),
      }));
    } catch (error) {
      console.error("Error processing image:", error);
    } finally {
      setIsLoadingImage(false);
    }
  };

  const handleUploadImage = async (file: File | null) => {
    if (!file) {
      return;
    }

    setIsLoadingImage(true);
    resetGeneratedImage();

    try {
      const image = await toDataURLByFile(file);
      const result = await removeBackground(image);

      setMasks(result);
      setShowModal(true);
    } catch (error) {
      console.error("Error processing uploaded image:", error);
    } finally {
      setIsLoadingImage(false);
    }
  };

  const handleChangeAsset = async (newButton: HTMLElement) => {
    const image = newButton.dataset.image;
    const mask = newButton.dataset.mask;

    setIsLoadingAssetImage(true);
    setActiveAssetImage({ image, mask });
    resetGeneratedImage();

    const result = { image };

    setIsLoadingAssetImage(false);

    newButton.style.borderColor = "#5295E0";
    setActiveBtn(newButton);

    const newImage = {
      image: result.image,
      style: {
        top: "100%",
        left: "180px",
        width: "150px",
        height: "150px",
      },
    };

    if (activeBtn) {
      activeBtn.style.borderColor = "";

      const lastIndex = draggableAssets.length - 1;
      setDraggableAssets(
        draggableAssets.map((image, i) => (i === lastIndex ? newImage : image)),
      );
      return;
    }

    setDraggableAssets([...draggableAssets, newImage]);
  };

  const handleMouseUpDraggableItems = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    const target = event.target as HTMLElement;
    draggableAssets[index].style = {
      top: target.style.top,
      left: target.style.left,
      width: target.style.width,
      height: target.style.height,
    };

    setDraggableAssets([...draggableAssets]);

    if (activeBtn === null) {
      return;
    }

    activeBtn.style.borderColor = "";
    setActiveBtn(null);
  };

  useEffect(() => {
    getTemplates().then((templates) => {
      setTemplates({ original: templates, transformed: templates });
    });
    getPlatforms().then(setPlatforms);
  }, []);

  const handleGenerateImage = async (prompt: string[]) => {
    if (activeImage === null) {
      return;
    }
    setIsLoadingImage(true);
    setLoadingImageGeneration(true);

    const getMask = await removeBackground(formatCanvasResult);
    const img_base64 = formatCanvasResult.replace(
      /^data:image\/jpeg;base64,/,
      "",
    );
    const mask_base64 = getMask[0].mask;
    const deliveryPrompt = customPrompt || prompt;

    try {
      const image = activeAssetImage
        ? await generateImageByPromptAndAsset(
            img_base64,
            mask_base64,
            String(deliveryPrompt),
            activeAssetImage.image,
            activeAssetImage.mask,
          )
        : await generateImageByPrompt(
            img_base64,
            mask_base64,
            String(deliveryPrompt),
          );

      setGeneratedImage(image);
      setIsLoadingImage(false);
    } catch {
      setGeneratedImage("");
    } finally {
      setLoadingImageGeneration(false);
      setIsLoadingImage(false);
    }
  };

  const onSave = () => {
    if (generatedImage === "") {
      return;
    }

    const href = "data:image/png;base64," + generatedImage;

    const link = document.createElement("a");
    link.href = href;
    link.download = "Download.png";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleApplyImage = (image: string) => {
    setFormatCanvasResult(image);
    setApplyImg(!applyImg);
    resetGeneratedImage();
  };

  const resetGeneratedImage = () => {
    setGeneratedImage("");
  };

  const handleNewRender = () => {
    resetGeneratedImage();
  };

  return (
    <>
      <div className={classNames(styles.wrapper)}>
        <Header onSave={onSave} />
        <div className={styles.main}>
          <div className={styles.sidebar}>
            <div className={styles.sidebar__primary}>
              <Nav
                onChange={handleActiveTab}
                items={navItems}
                defaultIndex={activeNavIndex}
              />
            </div>
            <div className={styles.sidebar__secondary}>
              {activeNavIndex === 0 && (
                <AsinTab
                  items={asinImages}
                  onChange={handleChangeImage}
                  onUploadImage={handleUploadImage}
                  isLoading={isLoadingImage}
                />
              )}

              {activeNavIndex === 1 && (
                <GenerateTab
                  templates={templates.transformed}
                  onGenerateImage={handleGenerateImage}
                  setCustomPrompt={setCustomPrompt}
                  applyImg={applyImg}
                />
              )}

              {activeNavIndex === 2 && (
                <AssetsTab
                  items={platforms}
                  onChange={handleChangeAsset}
                  onUploadImage={() => {}}
                  isLoading={isLoadingAssetImage}
                />
              )}

              {activeNavIndex === 3 && <SettingsTab />}
            </div>
          </div>
          <Canvas
            activeImage={activeImage}
            activeNavIndex={activeNavIndex}
            draggableEl={draggableAssets}
            onMouseUpDraggableItems={handleMouseUpDraggableItems}
            activeBtn={activeBtn}
            generatedImage={generatedImage}
            isLoading={isLoadingImageGeneration}
            setFormatCanvasResult={setFormatCanvasResult}
            onApplyImg={handleApplyImage}
            onNewRender={handleNewRender}
            setApplyImg={setApplyImg}
          />
        </div>
      </div>
      <MasksModal
        show={showModal}
        setShow={setShowModal}
        setActiveMask={setActiveImage}
        imgs={masks}
      />
    </>
  );
};

export default PhotoOptimisationDetails;
