import React, { useEffect, useState } from "react";
import { Modal, Form, Select, Button, DatePicker, Space } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import "./ModalStyles.css";
import CalendarModal from "@assets/icons/calendarModal";
import Dolar from "@assets/icons/dolar";
import ModalClose from "@assets/icons/modalClose";
import { Input } from "@components/Input";
import { useForm } from "antd/lib/form/Form";
import UploadModal from "@components/UploadModal";
import { CategoriesService } from "@services/amazon/categories/categories.service";

const { Option } = Select;

interface AddDataEntryModalProps {
  visible: boolean;
  onCancel: () => void;
  onSwitch?: () => void;
}

const AddDataEntryModal: React.FC<AddDataEntryModalProps> = ({
  onCancel,
  onSwitch,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [form] = useForm();
  const [feeCount, setFeeCount] = useState<number>(0);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const handleAddEntry = async () => {
    try {
      const values = await form.validateFields();

      const { dateStart, dateEnd, ...rest } = values;
      const date_start = dateStart ? dateStart.format("YYYY-MM-DD") : null;
      const date_end = dateEnd ? dateEnd.format("YYYY-MM-DD") : null;

      const amazonFeesObj: Record<string, { value: number }> = {};
      if (Array.isArray(rest.amazon_fees)) {
        rest.amazon_fees.forEach((feeItem: any) => {
          if (feeItem?.type) {
            amazonFeesObj[feeItem.type] = {
              value: feeItem?.value ? Number(feeItem.value) : 0,
            };
          }
        });
      }

      const pricesObj: Record<string, { value: number }> = {};
      if (Array.isArray(rest.other)) {
        rest.other.forEach((metricItem: any) => {
          if (metricItem?.type) {
            pricesObj[metricItem.type] = {
              value: metricItem?.value ? Number(metricItem.value) : 0,
            };
          }
        });
      }

      const finalPayload = {
        asin: rest.asin || "",
        date_start,
        date_end,
        estimated_fees: 0,
        cogs: Number(rest.cogs) || 0,
        net_sales: 0,
        units_sold_items: Number(rest.unitsSold) || 0,
        units_returned_items: 0,
        prices: pricesObj,
        amazon_fees: amazonFeesObj,
      };

      const dataToPost = [finalPayload];

      const cogsService = new CategoriesService();
      const response = await cogsService.sendCogsData(dataToPost);

      setIsModalOpen(false);
      onSwitch?.();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleValuesChange = () => {
    const currentFeeCount = form.getFieldValue("fee")?.length || 0;
    setFeeCount(currentFeeCount);
  };

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
    setIsModalOpen(false);
  };

  const handleCancelUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={onCancel}
        footer={null}
        width={750}
        className="custom-modal"
        closeIcon={
          <span
            style={{
              width: "14px",
              height: "14px",
              fontSize: "20px",
              color: "#FFFFFF",
            }}
          >
            ×
          </span>
        }
      >
        <div className="modal-header">
          <h2 className="modal-title">Add Data Entry</h2>
          <Button
            icon={<UploadOutlined />}
            className="btn-upload"
            type="default"
            onClick={handleOpenUploadModal}
          >
            Upload CSV
          </Button>
        </div>
        <Form form={form} layout="vertical" onValuesChange={handleValuesChange}>
          <Form.Item label="ASIN" name="asin" className="asin">
            <Input placeholder="Enter ASIN" size="large" />
          </Form.Item>
          <div className="modal-content">
            <div className="left-section">
              <Form.Item
                label="DATE START"
                name="dateStart"
                className="custom-date-picker"
              >
                <DatePicker
                  suffixIcon={null}
                  style={{ width: "100%" }}
                  inputRender={(inputProps, inputRef) => (
                    <div className="date-picker-input-container">
                      <CalendarModal />
                      <input {...inputProps} ref={inputRef} />
                    </div>
                  )}
                />
              </Form.Item>

              <Form.Item
                label="COGS, $"
                name="cogs"
                className="custom-date-picker"
              >
                <Input
                  size="large"
                  className="input"
                  paddingSize="dense"
                  icon={<Dolar />}
                  type="number"
                />
              </Form.Item>
              <Form.Item label="Price, $" className="custom-date-picker">
                <Input
                  size="large"
                  className="input"
                  paddingSize="dense"
                  icon={<Dolar />}
                  type="number"
                />
              </Form.Item>

              <Form.Item label="AMAZON FEE" className="custom-date-picker">
                <Form.List
                  name="amazon_fees"
                  initialValue={[{ type: "Aged Inventory Surcharge" }]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "type"]}
                            style={{ flex: 1, marginRight: 8 }}
                          >
                            <Select
                              placeholder="Select or enter Fee Type"
                              dropdownClassName="custom-dropdown"
                              style={{ height: "50px" }}
                            >
                              <Option value="Aged Inventory Surcharge">
                                Aged Inventory Surcharge
                              </Option>
                              <Option value="Base Fulfillment Fee">
                                Base Fulfillment Fee
                              </Option>
                              <Option value="Base Monthly Storage Fee">
                                Base Monthly Storage Fee
                              </Option>
                              <Option value="FBA Fulfillment Fees">
                                FBA Fulfillment Fees
                              </Option>
                              <Option value="FBA Inbound Placement Service Fee">
                                FBA Inbound Placement Service Fee
                              </Option>
                              <Option value="Monthly Inventory Storage Fee">
                                Monthly Inventory Storage Fee
                              </Option>
                              <Option value="Referral Fee">Referral Fee</Option>
                              <Option value="Refund Administration Fee">
                                Refund Administration Fee
                              </Option>
                              <Option value="Storage Utilization Surcharge">
                                Storage Utilization Surcharge
                              </Option>
                            </Select>
                          </Form.Item>
                        </Space>
                      ))}
                      <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => add()}
                        className="add-button"
                      >
                        ADD ANOTHER AMAZON FEE
                      </Button>
                    </>
                  )}
                </Form.List>
              </Form.Item>

              <Form.Item
                label="ADDITIONAL METRICS"
                className="custom-date-picker-metrics"
                style={{ width: "297px" }}
              >
                <Form.List
                  name="other"
                  initialValue={[{ type: "Net Sales", value: "" }]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name }) => (
                        <div key={key}>
                          <Space
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Form.Item
                              name={[name, "type"]}
                              style={{ flex: 1, marginRight: 8, marginTop: 0 }}
                            >
                              <Select
                                placeholder="Select or enter Fee Type"
                                dropdownClassName="custom-dropdown"
                              >
                                <Option value="Net Sales">Net Sales</Option>
                                <Option value="Net Proceeds">
                                  Net Proceeds
                                </Option>
                                <Option value="Contribution Profit">
                                  Contribution Profit
                                </Option>
                                <Option value="Contribution Margin">
                                  Contribution Margin
                                </Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name={[name, "value"]}
                              className="custom-date-picker"
                            ></Form.Item>
                          </Space>
                        </div>
                      ))}
                      <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => add()}
                        className="add-button"
                      >
                        ADD ANOTHER ADDITIONAL METRIC
                      </Button>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </div>

            <div className="right-section">
              <Form.Item
                label="DATE END"
                name="dateEnd"
                className="custom-date-picker"
              >
                <DatePicker
                  suffixIcon={null}
                  inputRender={(inputProps, inputRef) => (
                    <div className="date-picker-input-container">
                      <CalendarModal />
                      <input {...inputProps} ref={inputRef} />
                    </div>
                  )}
                />
              </Form.Item>

              <Form.Item
                label="UNITS SOLD, ITEMS"
                name="unitsSold"
                className="custom-date-picker"
              >
                <Input
                  placeholder="Enter Units Sold"
                  size="large"
                  type="number"
                />
              </Form.Item>

              <Form.Item
                label="UNITS RETURNED, ITEMS"
                className="custom-date-picker"
              >
                <Input
                  placeholder="Enter RETURNED"
                  size="large"
                  type="number"
                />
              </Form.Item>
              <Form.List name="amazon_fees">
                {(fields, { remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, ...restField }, index) =>
                        index > 0 && (
                          <div
                            key={key}
                            className="fee-list"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <Form.Item
                              {...restField}
                              label={index === 1 ? "VALUE, $" : ""}
                              name={[name, "value"]}
                              style={{ width: "279px", marginBottom: 0 }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter a value!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="$"
                                size="large"
                                type="number"
                              />
                            </Form.Item>

                            <Button
                              type="link"
                              onClick={() => remove(name)}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "20px",
                              }}
                            >
                              <ModalClose />
                            </Button>
                          </div>
                        ),
                    )}
                  </>
                )}
              </Form.List>

              <Form.List name="other">
                {(fields, { remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, ...restField }, index) =>
                        index > 0 && (
                          <div
                            key={key}
                            className="fee-list"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              position: "absolute",
                              top: `${
                                index < 2
                                  ? 485 + index * 82
                                  : 485 + 2 * 82 + (index - 2) * 62
                              }px`,
                              marginTop: `${(feeCount > 1 ? feeCount - 1 : 0) * 60}px`,
                              gap: "8px",
                            }}
                          >
                            <Form.Item
                              {...restField}
                              label={index === 1 ? "VALUE, $" : ""}
                              name={[name, "value"]}
                              style={{ width: "243px", marginBottom: 0 }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter a value!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="$"
                                size="large"
                                type="number"
                              />
                            </Form.Item>

                            <Button
                              type="link"
                              onClick={() => remove(name)}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "20px",
                              }}
                            >
                              <ModalClose />
                            </Button>
                          </div>
                        ),
                    )}
                  </>
                )}
              </Form.List>
            </div>
          </div>
          <Button
            type="primary"
            block
            style={{
              marginTop: 34,
              backgroundColor: "#5295E0",
              borderColor: "#5295E0",
            }}
            onClick={handleAddEntry}
          >
            ADD DATA ENTRY
          </Button>
        </Form>
      </Modal>
      <UploadModal
        isModalOpen={isUploadModalOpen}
        onCancel={handleCancelUploadModal}
      />
    </>
  );
};

export default AddDataEntryModal;
