import { Api } from "src/utils/api";
import { CANVAS_API_BASE_URL } from "./canvas.constant";

type PerformanceType = "All" | "Good" | "Mid" | "Poor" | "No Sales";

export interface IPerformanceTableData {
  performance: string;
  sales: number;
  spend: number;
  acos: number;
  cpc: number;
  key: number | string;
}

export interface IPerformanceTableDataCampaign extends IPerformanceTableData {
  campaign_name: string;
}

export class CanvasApi extends Api {
  constructor() {
    super(CANVAS_API_BASE_URL);
  }

  public getSalesPerformance = async (
    date_start: string,
    date_end: string,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("", {
      date_start,
      date_end,
    });
  };

  public getSalesPerformanceCampaign = async (
    date_start: string,
    date_end: string,
    performance: PerformanceType,
    saturation?: number,
  ): Promise<IPerformanceTableDataCampaign[]> => {
    return await this.get("/campaign", {
      date_start,
      date_end,
      performance,
      saturation,
    });
  };

  public getSalesPerformanceTarget = async (
    date_start: string,
    date_end: string,
    campaign_name: string,
    performance: PerformanceType,
    saturation?: number,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("/target", {
      date_start,
      date_end,
      campaign_name,
      performance,
      saturation,
    });
  };

  public getSalesPerformanceAdType = async (
    date_start: string,
    date_end: string,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("/ad-type", {
      date_start,
      date_end,
    });
  };

  public getSalesPerformanceMatchType = async (
    date_start: string,
    date_end: string,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("/match-type", {
      date_start,
      date_end,
    });
  };
}
