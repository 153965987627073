import { Checkbox, Modal } from "antd";
import styles from "./styles.module.scss";
import "../modal.scss";
import {
  WIDGET_METRIC_GROUPS,
  WIDGET_METRICS_CONFIG,
} from "@services/math/math.const";
import classNames from "classnames";
import { WidgetMetricKey } from "@services/math/math.service";
import info from "@assets/icons/i.svg";

interface SettingsModalProps {
  open: boolean;
  choosedWidgets: WidgetMetricKey[];
  setChoosedWidgets: (widgets: WidgetMetricKey[]) => void;
  maxWidgets?: number;
  onApply: () => void;
  onClose: () => void;
}

export const SettingsModal: React.FC<SettingsModalProps> = ({
  open,
  onClose,
  onApply,
  choosedWidgets,
  setChoosedWidgets,
  maxWidgets = 10,
}) => {
  const selectWidget = (key: string) => {
    if (choosedWidgets.includes(key)) {
      setChoosedWidgets(choosedWidgets.filter((widget) => widget !== key));
    } else {
      if (choosedWidgets.length >= maxWidgets) {
        return;
      }
      setChoosedWidgets([...choosedWidgets, key]);
    }
  };

  console.log(open);

  return (
    <Modal open={open} onCancel={() => onClose()} className={"modal"}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h2>Choose Metrics</h2>
          <span>
            Choose which metrics you want to show in the «Sales Performance»
          </span>
        </div>
        <div
          className={classNames(styles.keys, {
            [styles.keys__disabled]: choosedWidgets.length >= maxWidgets,
          })}
        >
          {WIDGET_METRIC_GROUPS.map((group) => (
            <div className={styles.keys__group}>
              <h3>{group.title}</h3>
              {group.keys.map((key) => {
                return (
                  <div className={styles.key}>
                    <Checkbox
                      onChange={() => selectWidget(key)}
                      checked={choosedWidgets.includes(key)}
                    />
                    <span>{WIDGET_METRICS_CONFIG[key].label}</span>
                    <img src={info} alt="" />
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <button onClick={() => setChoosedWidgets(choosedWidgets)}>
            reset to default
          </button>
          <button onClick={onApply}>Apply</button>
        </div>
      </div>
    </Modal>
  );
};
