import horizontalChart from "@assets/icons/horizontalChart";
import verticalChart from "@assets/icons/verticalChart";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { TypeTab, ViewTab } from "./Chart";

export const TabButton = ({
  label,
  isSelected,
  onClick,
  icon: Icon,
}: {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  icon?: React.ElementType;
}) => (
  <button
    className={classNames(styles.tab, isSelected && styles.active)}
    onClick={onClick}
  >
    {Icon && <Icon />}
    {label}
  </button>
);

export const viewTabs: { label: ViewTab; icon: React.ElementType }[] = [
  { label: "Sankey", icon: horizontalChart },
  { label: "Tree", icon: verticalChart },
];

export const typeTabs: TypeTab[] = [
  "Campaigns",
  "Ad Type",
  "Target Type",
  "Targeting",
  "Match type",
  "Performance",
];

export const TabsTypeList = ({
  selectedTab,
  setSelectedTab,
  isFunnelChart,
}: {
  selectedTab: TypeTab;
  setSelectedTab: (tab: TypeTab) => void;
  isFunnelChart?: boolean;
}) => {
  if (isFunnelChart && !typeTabs.includes("Funnel")) {
    typeTabs.push("Funnel");
  }
  return (
    <div className={styles.tabs}>
      {typeTabs.map((tab) => (
        <TabButton
          key={tab}
          label={tab}
          isSelected={selectedTab === tab}
          onClick={() => setSelectedTab(tab)}
        />
      ))}
    </div>
  );
};
