import { useState } from "react";
import { Template } from "../../types";
import classNames from "classnames";
import styles from "./styles.module.scss";
import Tabs from "../Tabs";
import Templates from "../Templates";
import reference1 from "../../images/reference-1.jpg";
import ControlledSelect from "../ControlledSelect";
import InputCheckbox from "../InputCheckbox";

const GenerateTab = ({
  templates,
  onGenerateImage,
  setCustomPrompt,
  applyImg,
}: {
  templates: Template[];
  onGenerateImage: (prompt: string[]) => void;
  setCustomPrompt: (prompt: string) => void;
  applyImg: boolean;
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [prompt, setPrompt] = useState("");

  const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(e.target.value);
    setCustomPrompt(e.target.value);
  };

  return (
    <>
      <Tabs list={["Templates", " Prompt Builder"]} onChange={setActiveIndex} />
      {activeIndex === 0 && (
        <Templates
          loading={false}
          templates={templates}
          onGenerateImage={onGenerateImage}
          applyImg={applyImg}
        />
      )}
      {activeIndex === 1 && (
        <>
          <div className={classNames(styles.inputs)}>
            <textarea
              className={styles.textarea}
              placeholder="Place your prompt here"
              value={prompt}
              onChange={handlePromptChange}
            ></textarea>
            <div className="input input--row">
              <div className="input__label">Reference Image</div>
              <div className="input__buttons">
                <button
                  className="input__button"
                  style={{ backgroundImage: `url(${reference1})` }}
                ></button>
                <button className="input__button">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.03195 0C1.37078 0 0.00390625 1.36689 0.00390625 3.02806V5.81256C0.00390625 7.47373 1.37078 8.83247 3.03195 8.83247H7.97586C9.63703 8.83247 11.0039 7.47373 11.0039 5.81256V3.02806C11.0039 1.36689 9.63703 0 7.97586 0H3.03195ZM3.03195 1.64799H7.97586C8.75169 1.64799 9.35594 2.25222 9.35594 3.02806V5.81256C9.35594 6.5884 8.75169 7.18452 7.97586 7.18452H3.03195C2.25612 7.18452 1.65187 6.5884 1.65187 5.81256V3.02806C1.65187 2.25222 2.25612 1.64799 3.03195 1.64799Z"
                      fill="#7C7C78"
                    />
                    <path
                      d="M14.0238 13.1675C12.3627 13.1675 11.0039 14.5262 11.0039 16.1874V18.9719C11.0039 20.6331 12.3627 22 14.0238 22H18.9759C20.637 22 21.9958 20.6331 21.9958 18.9719V16.1874C21.9958 14.5262 20.637 13.1675 18.9759 13.1675H14.0238ZM14.0238 14.8155H18.9759C19.7517 14.8155 20.3478 15.4116 20.3478 16.1874V18.9719C20.3478 19.7477 19.7517 20.3439 18.9759 20.3439H14.0238C13.248 20.3439 12.6519 19.7477 12.6519 18.9719V16.1874C12.6519 15.4116 13.248 14.8155 14.0238 14.8155Z"
                      fill="#7C7C78"
                    />
                    <path
                      d="M13.9246 9.10725L16.1571 11.3397C16.4763 11.6606 16.9943 11.6643 17.318 11.348L19.5505 9.14802C19.8766 8.82798 19.8803 8.30372 19.5588 7.97902C19.2396 7.65814 18.7216 7.6544 18.3979 7.97071L16.7418 9.59435L15.0857 7.93825C14.7643 7.61986 14.2463 7.61986 13.9236 7.94024C13.6008 8.26071 13.6008 8.78488 13.9246 9.10725Z"
                      fill="#7C7C78"
                    />
                    <path
                      d="M13.3024 3.59644C13.0853 3.59771 12.8775 3.68451 12.724 3.83799C12.5705 3.99147 12.4837 4.19928 12.4824 4.41634C12.4816 4.63481 12.5674 4.8447 12.7211 4.99994C12.8749 5.15519 13.0839 5.2431 13.3024 5.24439H15.9164V9.05989C15.9177 9.27836 16.0056 9.4874 16.1608 9.64112C16.3161 9.79485 16.5259 9.8807 16.7444 9.87984C16.9615 9.87856 17.1693 9.79176 17.3228 9.63826C17.4763 9.48477 17.5631 9.27696 17.5643 9.05989V4.41634C17.563 4.19929 17.4762 3.9915 17.3228 3.83802C17.1693 3.68454 16.9615 3.59774 16.7444 3.59644H13.3024Z"
                      fill="#7C7C78"
                    />
                    <path
                      d="M2.41502 12.8353C2.0889 13.1554 2.08516 13.6796 2.40671 14.0043C2.72679 14.3306 3.25102 14.3343 3.57571 14.0126L5.22368 12.389L6.87977 14.037C7.20214 14.3608 7.72638 14.3608 8.04877 14.037C8.3672 13.7157 8.3672 13.1975 8.04877 12.8761L5.80818 10.6436C5.48901 10.3227 4.97105 10.319 4.80927 10.4774L2.41502 12.8353Z"
                      fill="#7C7C78"
                    />
                    <path
                      d="M5.23235 12.0962C5.12349 12.0958 5.01562 12.1169 4.91496 12.1584C4.8143 12.1998 4.72285 12.2608 4.64587 12.3378C4.5689 12.4147 4.50792 12.5062 4.46646 12.6069C4.425 12.7075 4.40387 12.8154 4.4043 12.9242V17.5597C4.40388 17.6685 4.42502 17.7764 4.46648 17.877C4.50795 17.9777 4.56892 18.0691 4.6459 18.1461C4.72287 18.2231 4.81432 18.2841 4.91497 18.3255C5.01563 18.367 5.12349 18.3881 5.23235 18.3877H8.66629C8.77515 18.3881 8.88302 18.367 8.98368 18.3255C9.08434 18.2841 9.17579 18.2231 9.25277 18.1461C9.32975 18.0692 9.39072 17.9777 9.43218 17.877C9.47364 17.7764 9.49477 17.6685 9.49434 17.5597C9.49304 17.3412 9.40513 17.1322 9.24989 16.9784C9.09464 16.8247 8.88476 16.7389 8.66629 16.7397H6.05227V12.9242C6.05314 12.7058 5.96729 12.4959 5.81357 12.3406C5.65985 12.1854 5.45082 12.0975 5.23235 12.0962Z"
                      fill="#7C7C78"
                    />
                  </svg>
                </button>
                <button className="input__button">
                  <svg
                    width="18"
                    height="22"
                    viewBox="0 0 18 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.71429 19.5001C1.71429 20.1442 1.97015 20.7619 2.4256 21.2174C2.88104 21.6728 3.49876 21.9287 4.14286 21.9287H13.8571C14.5012 21.9287 15.119 21.6728 15.5744 21.2174C16.0298 20.7619 16.2857 20.1442 16.2857 19.5001V4.92868H1.71429V19.5001ZM4.14286 7.35725H13.8571V19.5001H4.14286V7.35725ZM13.25 1.28582L12.0357 0.0715332H5.96429L4.75 1.28582H0.5V3.71439H17.5V1.28582H13.25Z"
                      fill="#7C7C78"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <label className="input input--sm">
              <div className="input__label">Product</div>
              <input
                className="input__input"
                type="text"
                value="a bottle of Sugarbear PRO hair vitamins"
              />
            </label>
            <label className="input input--sm">
              <div className="input__label">Placement</div>
              <div className="input__row">
                <ControlledSelect
                  placeholder={"standing on"}
                  options={["value 1", "value 2"]}
                />
                <input
                  className="input__input"
                  type="text"
                  value="bathroom counter"
                />
              </div>
            </label>
            <label className="input input--sm">
              <div className="input__label">Background</div>
              <div className="input__row">
                <ControlledSelect
                  placeholder="in front of"
                  options={["value 1", "value 2"]}
                />
                <input
                  className="input__input"
                  type="text"
                  value="soft shadows"
                />
              </div>
            </label>
            <label className="input input--sm">
              <div className="input__label">Number of resuts</div>
              <div className="input__row">
                <ControlledSelect
                  placeholder="1"
                  options={["value 1", "value 2"]}
                />
              </div>
            </label>
            <InputCheckbox
              label="Correct Color"
              onChange={() => {}}
              defaultValue={true}
            />
            <label className="input input--sm">
              <div className="input__label">Render strength</div>
              <div className="input__row">
                <ControlledSelect
                  placeholder="Default"
                  options={["value 1", "value 2"]}
                />
              </div>
            </label>
            <label className="input input--sm">
              <div className="input__label">Color strength</div>
              <div className="input__row">
                <ControlledSelect
                  placeholder="Strong"
                  options={["value 1", "value 2"]}
                />
              </div>
            </label>
            <label className="input input--sm">
              <div className="input__label">Outline Strength</div>
              <div className="input__row">
                <ControlledSelect
                  placeholder="Weak"
                  options={["value 1", "value 2"]}
                />
              </div>
            </label>
          </div>

          <button
            className={"button button--sm button--m-0"}
            onClick={() => onGenerateImage([prompt])}
          >
            Generate image
          </button>
        </>
      )}
    </>
  );
};

export default GenerateTab;
