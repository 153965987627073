import WidgetsList from "@pages/Dashboard/features/Widgets/WidgetsList";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { useEffect, useState } from "react";
import { WidgetClass } from "@services/widget.service";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { endOfWeek, startOfWeek, subWeeks } from "date-fns";
import { Tab } from "@pages/Dashboard/features/Widgets/Tab";
import { useTranslation } from "react-i18next";
import { useSuplyStore } from "@pages/Dashboard/store/suplyChain.state";
import styles from "./styles.module.scss";

export function SupplyChainBlock() {
  const [widgets, setWidgets] = useState<IWidget[]>();
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const isRestrictedRoute = location.pathname.includes("supply-chain");
  const { selectedWidgets, dateRange } = useDashboardStore((state) => ({
    selectedWidgets: state.selectedWidgets,
    dateRange: state.dateRange,
  }));
  const { startDate, endDate } = dateRange;
  const { customSuplyWidgets } = useSuplyStore((state) => ({
    customSuplyWidgets: state.customSuplyWidgets,
  }));

  useEffect(() => {
    const fetchWidgets = async () => {
      setIsLoading(true);
      const widgetClass = new WidgetClass();

      const startDateString = startDate.toISOString().split("T")[0];
      const endDateString = endDate.toISOString().split("T")[0];
      const compareStartDate = startOfWeek(subWeeks(startDate, 1))
        .toISOString()
        .split("T")[0];
      const compareEndDate = endOfWeek(subWeeks(startDate, 1))
        .toISOString()
        .split("T")[0];

      const response = await widgetClass.getWidgetsData(
        selectedWidgets,
        startDateString,
        endDateString,
        compareStartDate,
        compareEndDate,
      );

      const newTitles = [
        "Transport Cost",
        "Safety Stock",
        "Shipping Costs",
        "Storage Costs",
        "Forecasting Accuracy",
        "Inventory Turnover",
        "Lost Sales",
        "Contribution Margin",
      ];

      const updatedWidgets = response
        .map((widget, index) => {
          if (index < newTitles.length) {
            return {
              ...widget,
              title: newTitles[index].trim(),
              name: newTitles[index].trim(),
              value: widget.value || "N/A",
            };
          }
          return widget;
        })
        .slice(0, 8);

      setWidgets(updatedWidgets);
      setIsLoading(false);
    };

    fetchWidgets();
  }, [selectedWidgets, dateRange]);

  return (
    <div>
      <header className="dashboard-widgets__header">
        <h1 className={styles.headerTitle}>{t("Executive Metrics")}</h1>
        <Tab
          customSelectedWidgets={customSuplyWidgets}
          isRestrictedRoute={isRestrictedRoute}
        />
      </header>
      <WidgetsList widgets={widgets} isLoading={isLoading} />
    </div>
  );
}
