import React from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "@hooks/useTitle";
import PageWrapper from "@components/PageWrapper/PageWrapper";
import { SupplyChainBlock } from "./components/index";

export const SupplyChain: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("window.finance"));

  return (
    <PageWrapper>
      <SupplyChainBlock />
    </PageWrapper>
  );
};
