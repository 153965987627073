import classNames from "classnames";
import styles from "./styles.module.scss";
import { useState } from "react";

const Nav = ({
  onChange,
  items,
  defaultIndex = 0,
}: {
  onChange: (index: number) => void;
  items: { icon: string; label: string }[];
  defaultIndex?: number;
}) => {
  const [activeNavIndex, setActiveNavIndex] = useState(defaultIndex);

  const handleClick = (index: number) => {
    setActiveNavIndex(index);
    onChange(index);
  };

  return (
    <ul className={styles.nav}>
      {items.map((widget, i) => {
        return (
          <li
            key={i}
            className={classNames(
              styles.nav__item,
              i === activeNavIndex && styles["nav__item--active"],
            )}
            onClick={() => handleClick(i)}
          >
            {<img src={widget.icon} alt="" />}
            <span>{widget.label}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default Nav;
