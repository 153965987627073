import classNames from "classnames";
import styles from "./styles.module.scss";
import InputFile from "../InputFile";
import { useState } from "react";
import Loader from "../Loader";

const AsinTab = ({
  items,
  onChange,
  onUploadImage,
  isLoading = false,
}: {
  items: string[];
  onChange: (index: number) => void;
  onUploadImage: (file: File | null) => void;
  isLoading?: boolean;
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(-1);

  const handleChange = (index: number) => {
    if (isLoading) {
      return;
    }

    onChange(index);
    setActiveImageIndex(index);
  };

  const handleUploadImage = (file: File | null) => {
    if (isLoading) {
      return;
    }

    onUploadImage(file);
  };

  return (
    <>
      <InputFile onChange={handleUploadImage} />
      <div
        className={classNames(
          styles.assetsWrapper,
          isLoading && styles.loading,
        )}
      >
        {isLoading && <Loader />}
        <ul className={classNames(styles.assets)}>
          {items.map((image, i) => {
            return (
              <li
                key={i}
                className={classNames(
                  styles.image,
                  i === activeImageIndex && styles["image--active"],
                )}
                style={{ backgroundImage: `url(${image})` }}
                onClick={() => handleChange(i)}
              ></li>
            );
          })}
        </ul>
      </div>
      <button
        hidden
        className={"button button--sm"}
        onClick={() => setActiveImageIndex(-1)}
      >
        reset
      </button>
    </>
  );
};

export default AsinTab;
