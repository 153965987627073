import { useEffect, useMemo, useState } from "react";
import { TabsTypeList } from "@pages/AdsAnalitics/components/SanKey/utils";
import { TypeTab } from "@pages/AdsAnalitics/components/SanKey/Chart";
import { Input } from "@components/Input";
import { Select, Option } from "@components/Select";
import { ExpandableTable } from "@components/ExpandebleTable";
import Search from "@assets/icons/search";
import StarKeyword from "@assets/icons/StarKeyword";
import { useKeywordStore } from "src/store/keyword.state";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { ProductsService } from "@services/amazon/products/products.service";
import { ProductWithImageLink } from "@services/amazon/products/types";
import { CanvasApi, IPerformanceTableData } from "@services/canvas/canvas.api";
import styles from "./styles.module.scss";
import { formatData } from "../../mock";
import { formattedKeys, getColumns, getTabData, toIsoDate } from "./utils";

/**
 * We extend the IPerformanceTableData to add fields for nested expansions.
 */
interface INestedPerformanceData extends IPerformanceTableData {
  children?: INestedPerformanceData[];
  hasFetchedChildren?: boolean;
}

/**
 * A helper to update a node's `children` in a nested array by a matching `recordKey`.
 */
function updateRecordChildren(
  records: INestedPerformanceData[],
  recordKey: number,
  children: INestedPerformanceData[],
): INestedPerformanceData[] {
  return records.map((item) => {
    if (item.key === recordKey) {
      return {
        ...item,
        children,
        hasFetchedChildren: true,
      };
    } else if (item.children && item.children.length > 0) {
      return {
        ...item,
        children: updateRecordChildren(item.children, recordKey, children),
      };
    }
    return item;
  });
}

interface CampaignsTableProps {
  hideAsin?: boolean;
}

export const CampaignsTable = ({ hideAsin }: CampaignsTableProps) => {
  const { keyword, setKeywords } = useKeywordStore();
  const { dateRange } = useDashboardStore();
  const canvasApi = useMemo(() => new CanvasApi(), []);

  const [performanceData, setPerformanceData] = useState<
    INestedPerformanceData[] | undefined
  >();

  const [tableData, setTableData] = useState<any[]>([]);

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedTab, setSelectedTab] = useState<TypeTab>("Campaigns");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [choosedKeyword, setChoosedKeyword] = useState("");

  const [tooltipTabsTitle, setTooltipTabsTitle] = useState("");
  const [tooltipKeysTitle, setTooltipKeysTitle] = useState("");

  const [asin, setAsin] = useState<string>();
  const [asinOptions, setAsinOptions] = useState<Option[]>();

  /** Fetches products for the ASIN dropdown. */
  useEffect(() => {
    const fetchAsinOptions = async () => {
      try {
        const productsService = new ProductsService();
        const productData = await productsService.getOwnProducts();
        const options = productData.map((product: ProductWithImageLink) => ({
          id: product.asin,
          text: product.item_name,
          img: product.image_link,
          asin: product.asin,
        }));
        setAsinOptions(options);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchAsinOptions();
  }, [asin]);

  /**
   * Fetch top-level performance data if the selected tab is "Performance".
   */
  useEffect(() => {
    const fetchPerformanceData = async () => {
      if (selectedTab !== "Performance") return;

      try {
        const { startDate, endDate } = dateRange;
        const fetchedData = await canvasApi.getSalesPerformance(
          toIsoDate(startDate),
          toIsoDate(endDate),
        );
        const shapedData: INestedPerformanceData[] = fetchedData.map(
          (item, index) => ({
            ...item,
            key: index,
            level: 1,
            hasFetchedChildren: false,
            children: [],
          }),
        );

        setPerformanceData(shapedData);
      } catch (error) {
        console.error("Error fetching performance data:", error);
      }
    };

    fetchPerformanceData();
  }, [selectedTab, dateRange, canvasApi]);

  /**
   * Handles row expansion for the Performance tab.
   * On expand, we fetch children data from getSalesPerformanceCampaign.
   * On collapse, we remove them from `expandedRowKeys` and optionally clear children.
   */
  const handleExpand = async (expanded: boolean, record: any) => {
    if (!expanded) {
      setExpandedRowKeys((prev) => prev.filter((k) => k !== record.key));
      setPerformanceData(
        (prev) => prev && updateRecordChildren(prev, record.key, []),
      );
      return;
    }

    setExpandedRowKeys((prev) => [...prev, record.key]);

    try {
      const { startDate, endDate } = dateRange;
      let childrenData = [];

      if (record.level === 1) {
        childrenData = await canvasApi.getSalesPerformanceCampaign(
          toIsoDate(startDate),
          toIsoDate(endDate),
          record.performance,
        );
      } else if (record.level === 2) {
        childrenData = await canvasApi.getSalesPerformanceTarget(
          toIsoDate(startDate),
          toIsoDate(endDate),
          record.campaign_name,
          record.performanceKey,
        );
      }

      const shapedChildren = childrenData.map((child, index) => ({
        ...child,
        key: `${record.key}-${index}`,
        level: record.level + 1,
        hasFetchedChildren: false,
        performance:
          child.targeting_text || child.campaign_name || child.performance,
        performanceKey: child.performance,
        children: [],
      }));

      setPerformanceData(
        (prev) =>
          prev && updateRecordChildren(prev, record.key, shapedChildren),
      );
    } catch (error) {
      console.error("Error fetching children data:", error);
    }
  };

  /**
   * Every time performanceData or the selectedTab changes,
   * recalculate `tableData` so that the table sees the updated structure.
   */
  useEffect(() => {
    const sourceData = getTabData(selectedTab, performanceData);

    const finalData = formatData({
      data: sourceData || [],
      openTooltip: setTooltipTabsTitle,
      openedTooltip: tooltipTabsTitle,
      keyTooltip: tooltipKeysTitle,
      setKeyTooltip: setTooltipKeysTitle,
      keyword,
      setKeywords,
      withKeywords: selectedTab === "Campaigns",
    });

    setTableData(finalData);
  }, [
    selectedTab,
    performanceData,
    tooltipTabsTitle,
    tooltipKeysTitle,
    keyword,
    setKeywords,
  ]);

  /**
   * Handle row selection changes (checkbox in first column).
   */
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      {/* ASIN dropdown (hidden if hideAsin=true) */}
      {!hideAsin && (
        <div className={styles.inputBox}>
          <h2 className={styles.asinText}>ASIN</h2>
          <Select
            value={asin}
            placeholder="Select asin"
            options={asinOptions}
            onChange={(value: string) => setAsin(value)}
            className={styles.asin}
          />
        </div>
      )}

      <TabsTypeList
        isFunnelChart
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      <div className={styles.filters}>
        <Input
          value={search}
          placeholder="Search a campaign..."
          onChange={setSearch}
          className={styles.input}
          icon={<Search />}
        />
        <Select
          value={filter}
          options={["All", "Active", "Inactive"]}
          onChange={(value: string) => setFilter(value)}
          placeholder="Filters"
        />
        {selectedTab === "Campaigns" && (
          <Select
            value={choosedKeyword}
            options={formattedKeys(keyword.keywords)}
            onChange={(value: string) => setChoosedKeyword(value)}
            className={styles.select}
            placeholder={
              <span className={styles.placeholder}>
                <StarKeyword fill="#5C5C5A" />
                Custom Keywords
              </span>
            }
          />
        )}

        <button className={styles.export__button}>EXPORT</button>
      </div>

      <div className={styles.table}>
        <ExpandableTable
          columns={getColumns(selectedTab)}
          data={tableData}
          rowSelection={rowSelection}
          withCustomScroll
          expandable={
            selectedTab === "Performance"
              ? {
                  expandedRowKeys,
                  onExpand: handleExpand,
                  rowExpandable: () => true,
                }
              : undefined
          }
        />
      </div>
    </>
  );
};
