import { ExpandableTable } from "@components/ExpandebleTable";
import { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { TabsTypeList } from "./Tabs";
import { getColumns, getData, getSelectData } from "./utils";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { Select } from "@components/Select";
import { ProductsService } from "@services/amazon/products/products.service";
import { ProductWithImageLink } from "@services/amazon/products/types";
import { Option } from "@components/Select";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { CanvasApi, IPerformanceTableData } from "@services/canvas/canvas.api";

interface WhatIfTableProps {
  hideAsin?: boolean;
}

export const WhatIfTable = ({ hideAsin }: WhatIfTableProps) => {
  const canvasApi = useMemo(() => new CanvasApi(), []);
  const { keys, setKeys, sankeyTab, setSankeyTab } = useAdsAnaliticsStore();
  const { dateRange } = useDashboardStore();
  const [selectedCampaignsKeys, setSelectedCampaignsKeys] = useState([]);
  const [selectedPerformanceKeys, setSelectedPerformanceKeys] = useState([]);
  const [selectedTargetingKeys, setSelectedTargetingKeys] = useState([]);
  const [selectedAddTypeKeys, setSelectedAddTypeKeys] = useState([]);
  const [selectedMatchTypeKeys, setSelectedMatchTypeKeys] = useState([]);
  const [asin, setAsin] = useState<string>();
  const [asinOptions, setAsinOptions] = useState<Option[]>();
  const [performanceData, setPerformanceData] = useState<
    IPerformanceTableData[]
  >([]);

  const onSelectChange = (selectedRowKeys: any) => {
    const { data, colName } = getSelectData(sankeyTab, performanceData);

    const flattenedArray = data.flatMap((item) => [
      item,
      ...(item.children || []),
    ]);

    const keysNames = flattenedArray
      .filter((item) => {
        return selectedRowKeys?.includes(item.key) && item.key.includes("-");
      })
      .map((item) => item[colName]);

    setKeys({ ...keys, [colName]: keysNames });

    switch (sankeyTab) {
      case "Campaign":
        setSelectedCampaignsKeys(selectedRowKeys);
        break;
      case "Performance":
        setSelectedPerformanceKeys(selectedRowKeys);
        break;
      case "Ad Type":
        setSelectedAddTypeKeys(selectedRowKeys);
        break;
      case "Match type":
        setSelectedMatchTypeKeys(selectedRowKeys);
        break;
      default:
        setSelectedTargetingKeys(selectedRowKeys);
    }
  };

  const rowSelection = {
    selectedRowKeys: (() => {
      switch (sankeyTab) {
        case "Campaign":
          return selectedCampaignsKeys;
        case "Performance":
          return selectedPerformanceKeys;
        case "Ad Type":
          return selectedAddTypeKeys;
        case "Match type":
          return selectedMatchTypeKeys;
        default:
          return selectedTargetingKeys;
      }
    })(),
    onChange: onSelectChange,
  };

  useEffect(() => {
    const fetchData = async () => {
      const productsService = new ProductsService();
      try {
        const data = await productsService.getOwnProducts();
        const options = data.map((product: ProductWithImageLink) => ({
          id: product.asin,
          text: product.item_name,
          img: product.image_link,
          asin: product.asin,
        }));

        setAsinOptions(options);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, [asin]);

  /**
   * Fetches performance data when the selected tab is "Performance".
   */
  useEffect(() => {
    const fetchPerformanceData = async () => {
      if (sankeyTab !== "Performance") return;
      try {
        const { startDate, endDate } = dateRange;
        const formatDate = (date: Date) => date.toISOString().split("T")[0];
        const fetchedData = await canvasApi.getSalesPerformance(
          formatDate(startDate),
          formatDate(endDate),
        );
        setPerformanceData(fetchedData);
      } catch (error) {
        console.error("Error fetching performance data:", error);
      }
    };
    fetchPerformanceData();
  }, [sankeyTab, dateRange, canvasApi]);

  return (
    <div className={styles.container}>
      {!hideAsin && (
        <div className={styles.inputBox}>
          <h2 className={styles.asinText}>ASIN</h2>
          <Select
            value={asin}
            placeholder="Select asin"
            options={asinOptions}
            onChange={(value: string) => {
              setAsin(value);
            }}
            className={styles.asin}
          />
        </div>
      )}
      <TabsTypeList selectedTab={sankeyTab} setSelectedTab={setSankeyTab} />
      <ExpandableTable
        withCustomScroll
        columns={getColumns(sankeyTab)}
        data={getData(sankeyTab, performanceData)}
        rowSelection={rowSelection}
      />
    </div>
  );
};
