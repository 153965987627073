import {
  adTypeColumns,
  adtypeData,
  funnelColumns,
  funnelData,
  matchColumns,
  matchData,
  productsColumns,
  productsData,
  targetData,
  targetingColumns,
  targetingData,
  targetTypeColumns,
} from "../../mock";
import { campaignsColumns, campaignsData } from "../WhatIfTable/mock";
import { performanceColumns } from "./columns";
import { getStarColor } from "@components/ExpandebleTable/KeyTooltip";
import { TypeTab } from "@pages/AdsAnalitics/components/SanKey/Chart";
import { IPerformanceTableData } from "@services/canvas/canvas.api";
import styles from "./styles.module.scss";
import { Checkbox } from "antd";
import StarKeyword from "@assets/icons/StarKeyword";

/** A small helper to format dates into YYYY-MM-DD. */
export const toIsoDate = (date: Date) => date.toISOString().split("T")[0];

/**
 * Returns data array based on the selected tab.
 */
export const getTabData = (
  tab: TypeTab,
  performanceData?: IPerformanceTableData[],
) => {
  switch (tab) {
    case "Campaigns":
      return campaignsData;
    case "Ad Type":
      return adtypeData;
    case "Funnel":
      return funnelData;
    case "Target Type":
      return targetData;
    case "Targeting":
      return targetingData;
    case "Performance":
      return performanceData;
    case "Match type":
      return matchData;
    default:
      return productsData;
  }
};

/**
 * Returns columns based on the selected tab.
 */
export const getColumns = (tab: TypeTab) => {
  switch (tab) {
    case "Campaigns":
      return campaignsColumns;
    case "Ad Type":
      return adTypeColumns;
    case "Funnel":
      return funnelColumns;
    case "Target Type":
      return targetTypeColumns;
    case "Targeting":
      return targetingColumns;
    case "Performance":
      return performanceColumns;
    case "Match type":
      return matchColumns;
    default:
      return productsColumns;
  }
};

/**
 * Formats keyword options for the Select component.
 */
export const formattedKeys = (keys: string[]) => {
  return keys.map((key, index) => (
    <div className={styles.keys__container} key={key}>
      <Checkbox />
      <div className={styles.star}>
        <StarKeyword fill={getStarColor(index)} />
      </div>
      <span>{key}</span>
    </div>
  ));
};
